export const GENERAL_STATUS = {
    0: "Pendiente",
    1: "Completado",
    2: "Pago finalizado",
    3: "Pago pendiente",
    4: "Envio pendiente",
    5: "Retirado",
    6: "Retiro pendiente",
    7: "Pago y envio/retiro pendiente",
    8: "Entregado",
    9: "Cancelado",
    10: "En preparación",    
    11: "Cambiado"
}

export const TICKET_STATUS = {
    2: "Pago finalizado",
    3: "Pago pendiente",
    9: "Cancelado"
}

export const TICKET_PAYMENT_METHOD = {
    1: "Efectivo",
    2: "Transferencia",
    3: "Mercado Pago",
    4: "Mercado Libre",    
    5: "POS"
}

export const SHIPPING_TYPE = {
    1: "Envio a domicilio",
    2: "Retiro en local",
    3: "Envio por MercadoLibre"
}

export const SHIPPING_STATUS = {
    4: "Envio pendiente",
    5: "Retirado",
    6: "Retiro pendiente",
    8: "Entregado",
    9: "Cancelado"
}

export const PRODUCT_TYPE = {
    1: "Producto",
    2: "Accesorio"
}

export const RESTOCKING_PAYMENT_STATUS = {
    1: "Pago finalizado",
    2: "Pago pendiente",
}

export const MOVEMENTS_CURRENCY = {
    1: "$",
    2: "USD",
}

export const SPEND_TYPE = {
    1: "Fijo",
    2: "Variable",
}

export const TRANSACTION_TYPE = {
    1: "Venta",
    2: "Gasto",
    3: "Transferencia",
    4: "Deposito",
}

export const INVOICE_TYPE = {
    101: "eTicket",
    111: "eFactura",
    102: "NC eTicket",
    112: "NC eFactura",
}

export const TICKET_INVOICE_STATUS = {
    0: "PENDIENTE",
    1: "FACTURADO",
    2: "IGNORADO",
}
