import React, { Component } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import ModalPurchase from '../components/ModalPurchase';
import { SHIPPING_STATUS, SHIPPING_TYPE,  } from '../utils/enums'
import { compare, updateArrows } from '../utils/table'
import { getTextColorClassShipping } from '../utils/formats'
import { getProviders } from '../utils/functions';

var config = process.env;

class TableShippingComponent extends Component {

	constructor(){
        super();
        this.state = {
            purchaseId: 0,
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false,
            providers:{}
        }
	}

	async componentDidMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        await fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'shipping', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data,                    
                });
            }
        });
        let providers = await getProviders("shipping")
        this.setState({providers: providers, loaded: true })
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay envios</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    createModals = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=0; i<this.state.data.length; i++) {
                var data = this.state.data[i];
                items.push(
                    this.createModalChangeTrack(data.shippingId, data.status, data.provider, data.cost)
                );
            }
            return (
                <div>{ items }</div>
            );
        }
    }

    getItemsShipping = (currentStatus) => {
        let items = [];
        if (currentStatus == 4) {
            items.push(<option value="4">{ SHIPPING_STATUS[4] }</option>);
            items.push(<option value="8">{ SHIPPING_STATUS[8] }</option>);
        }
        else if (currentStatus == 6) {
            items.push(<option value="6">{ SHIPPING_STATUS[6] }</option>);
            items.push(<option value="5">{ SHIPPING_STATUS[5] }</option>);
        }
        return items;
    }

    getItemsProviders = () => {
        let items = [];
        this.state.providers.forEach((provider) => {
            items.push(<option value={provider.providerId}>{ provider.name }</option>);
        })
        return items;
    }

    createModalChangeTrack = (shippingId, currentStatus, currentProvider, currentCost) => {
        return (
            <div className="modal fade" id={`modal-${shippingId}`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Cambiar estado de envío</h4>
                        </div>     
                        <div className="modal-body">
                            <p>Estado</p>
                            <select id={`status-${shippingId}`} className="custom-select" defaultValue={currentStatus}>
                                { this.getItemsShipping(currentStatus) }
                            </select>
                        </div>                        
                        <div className="modal-body">
                            <p>Provider</p>
                            <select id={`provider-${shippingId}`} className="custom-select" defaultValue={currentProvider}>
                                { this.getItemsProviders() }
                            </select>
                        </div>                                            
                        <div className="modal-body">
                            <p>Costo de envío</p>
                            <input type="text" class="form-control" id={`cost-${shippingId}`} defaultValue={currentCost}/>
                        </div>  
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal"
                                onClick={() =>  this.onUpdateItem(shippingId)}>
                                Actualizar
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onUpdateItem = (shippingId) => {
        var value = document.getElementById("status-" + shippingId).value;
        var provider = document.getElementById("provider-" + shippingId).value;
        var cost = document.getElementById("cost-" + shippingId).value;
        var request = {
            method: 'PATCH',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                shippingId: parseInt(shippingId),
                status: parseInt(value),
                provider: parseInt(provider),
                cost: parseInt(cost)
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'shipping/', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                window.location = "/shipping"
            }
        });
    }
    
    onTicketClicked = (purchaseId) => {
        this.setState({ purchaseId: purchaseId })
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td tabindex="0" class="sorting_1">{item.shippingId}</td>
                <td id={`item-status-${item.shippingId}`} class={getTextColorClassShipping(item.status)}>{ SHIPPING_STATUS[item.status] }</td>
                <td data-toggle="modal" data-target={`#modal-view`} onClick={() => this.onTicketClicked(item.purchaseId)}><a href="#"> {item.purchaseId} </a></td>
                <td>{item.address}</td>
                <td>{item.hourPrefference}</td>
                <td id={`item-provider-${item.shippingId}`} >{item.providerName}</td>
                <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                        <button 
                            id={`button-status-${item.shippingId}`}
                            data-toggle="modal"
                            data-target={`#modal-${item.shippingId}`}
                            type="button" 
                            disabled={item.status==5||item.status==8||item.status==9 ? true : false}
                            class="btn btn-white"><i class="material-icons">edit</i>
                        </button>
                    </div>
                </td>
            </tr>
        );
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = []
        for (var i=0; i<this.state.data.length; i++) {
            if (
                this.state.data[i].address.toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].providerName.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].hourPrefference.toString().toLowerCase().includes(input.toLowerCase()) ||
                (this.state.data[i].ticketId != null && this.state.data[i].ticketId.toString().toLowerCase().includes(input.toLowerCase())) ||
                SHIPPING_STATUS[this.state.data[i].status].toString().toLowerCase().includes(input.toLowerCase())
                 ) {
                newList.push( this.state.data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "shippingId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}} aria-sort="ascending">ID</th>
                            <th onClick={(e) => this.orderData(e, "status")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Estado</th>
                            <th onClick={(e) => this.orderData(e, "ticketId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Compra</th>
                            <th onClick={(e) => this.orderData(e, "address")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '25%'}}>Dirección</th>
                            <th onClick={(e) => this.orderData(e, "hourPrefference")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Preferencia horaria</th>
                            <th onClick={(e) => this.orderData(e, "providerName")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Proveedor</th>
                            <th rowspan="1" colspan="1" style={{width: '10%'}}>Acciones</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                { this.createModals() }

                { <ModalPurchase purchaseId={this.state.purchaseId}/> }

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableShippingComponent;