import React, { Component } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import { compare, updateArrows } from '../utils/table'
import { getTextColorClassStock, convertToMoney } from '../utils/formats'
import { getProviders } from '../utils/functions';

var config = process.env;

class TableProductsComponent extends Component {

	constructor(){
        super();
        this.state = {
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false,
            providers: {}
		}
	}

	async componentWillMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product', request)
        .then(results => { return results.json(); })
        .then(data => {
            this.setState({
                data: data.data,
                filterData: data.data,
                loaded: true
            });
        });
        let providers = await getProviders("product")
        let providersDic = {}
        providers.map((provider) => {
            providersDic[provider.providerId] = provider.name
        })
        this.setState({providers: providersDic })
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay productos</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    createModals = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=0; i<this.state.data.length; i++) {
                var data = this.state.data[i];
                items.push(
                    this.createModalRemove(data.code, data.prefix, data.name)
                );
            }
            return (
                <div>{ items }</div>
            );
        }
    }

    createModalRemove = (code, prefix, name) => {
        return (
            <div className="modal fade" id={`modal-remove-${code}`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Eliminar</h4>
                        </div>
                        <div className="modal-body">
                            <p>¿Estás seguro que deseas eliminar el producto?</p>
                            <p style={{marginBottom: '7px'}}><b>Código:</b> {prefix}{code}</p>
                            <p><b>Nombre:</b> {name}</p>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal"
                                onClick={() =>  this.onDeleteItem(code)}>
                                Confirmar
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td tabindex="0" class="sorting_1">{item.prefix}{item.code}</td>
                <td>{item.name}</td>
                <td>$ {convertToMoney(item.price)}</td>
                <td>{item.discount} %</td>
                <td style={{fontWeight: (item.finalPrice < item.price) ? '700' : '500'}}>$ {convertToMoney(item.finalPrice)}</td>
                <td>{this.state.providers[item.provider]}</td>
                <td class={getTextColorClassStock(item.stock, item.minStock)}>{item.stock}</td>
                <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                    {this.props.role == 1 ? (
                            <button
                                onClick={() => this.onViewAnalytics(item.code)}
                                type="button"
                                className="btn btn-white">
                                <i className="material-icons">insert_chart_outlined</i>
                            </button>
                        ) : null}
                        <button onClick={() => this.onUpdateItem(item.code)} type="button" class="btn btn-white"><i class="material-icons"></i></button>
                        <button 
                            style={{display: (this.props.role == 2) ? 'none' : 'table-cell'}}
                            data-toggle="modal"
                            data-target={`#modal-remove-${item.code}`}
                            type="button" 
                            class="btn btn-white">
                            <i class="material-icons"></i>
                        </button>
                    </div>
                </td>
            </tr>
        );
    }

    onDeleteItem = (code) => {
        var request = {
            method: 'DELETE',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                window.location.href = '/boxGames';
            }
        });
    }

    onUpdateItem = (code) => {
        window.location.href = `/editBoxGame?code=${code}`;
    }

    onViewAnalytics = (code) => {
        window.location.href = `/productAnalytics?code=${code}`;
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var newList = []
        var input = document.getElementById("input_search").value;
        const regex = /stock=(\d+)/g;
        let match = regex.exec(input.toLowerCase())
        console.log(match)
        if (match != null && match.length > 0)
        {
            for (var i=0; i<this.state.data.length; i++) {
                if (this.state.data[i].stock == parseInt(match[1], 10))
                    newList.push(this.state.data[i]);
            }
        }
        else
        {
            for (var i=0; i<this.state.data.length; i++) {
                var code = this.state.data[i].prefix + this.state.data[i].code;
                if (code.toString().toLowerCase().includes(input.toLowerCase()) ||
                    this.state.data[i].name.toLowerCase().includes(input.toLowerCase()) ||
                    this.state.data[i].price.toString().toLowerCase().includes(input.toLowerCase()) ||
                    this.state.providers[this.state.data[i].provider].toString().toLowerCase().includes(input.toLowerCase()) ||
                    this.state.data[i].discount.toString().toLowerCase().includes(input.toLowerCase()) ||
                    this.state.data[i].stock.toString().toLowerCase().includes(input.toLowerCase())) {
                    newList.push( this.state.data[i] );
                }
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "code")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}} aria-sort="ascending">Código</th>
                            <th onClick={(e) => this.orderData(e, "name")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '25%'}}>Nombre</th>
                            <th onClick={(e) => this.orderData(e, "price")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Precio</th>
                            <th onClick={(e) => this.orderData(e, "discount")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Descuento</th>
                            <th onClick={(e) => this.orderData(e, "finalPrice")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Precio final</th>
                            <th onClick={(e) => this.orderData(e, "provider")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Proveedor</th>
                            <th onClick={(e) => this.orderData(e, "stock")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Stock</th>
                            <th tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1">Acciones</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                { this.createModals() }

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableProductsComponent;