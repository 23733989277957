import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';


var config = process.env;

class OpenCashComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: ''
    };
  }

    handleChange = (e) => {
        const value = e.target.value.replace(/[^\d,]/g, '');
        const cleanedValue = value.replace(/,/g, '.');
        this.setState({ amount: cleanedValue });
    };

    formatNumber = (num) => {
        if (num === '') return '';
        const parsedNumber = parseFloat(num.replace(/\./g, ''));
        if (isNaN(parsedNumber)) return '';
        return `$${parsedNumber.toLocaleString('de-DE')}`;
      };

  openCashRegister = () => {
    if (this.state.amount == '')
    {
        console.log("ammount empty")
        const inputAmount = document.getElementById('amount');
        inputAmount.classList.add("is-invalid");
    }
    else
    {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                open_money: this.state.amount,
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/openCashRegister', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error == 0 && data.data.status == 1) {
                window.location.href = "/purchase";
            }
        });
    }
}

  render() {
    return (
        <div className="col-lg-12">
                    <HeaderComponent title="Caja" subtitle="Apertura de caja"/>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-3 card card-small mb-4" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                            <div className="row">
                                <div className="col">  
                                    <span class="text-muted d-block mb-2 text-center">Datos</span>
                                    <div className="col-md-4 d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                        <label for="date">Fecha: </label>
                                        <span class="text-muted d-block mb-2">{new Date().toLocaleDateString()}</span>
                                    </div>  
                                    <div className="col-md-4 d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                        <label for="user">Usuario: </label>
                                        <span class="text-muted d-block mb-2">{localStorage.getItem('firstName')}</span>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-small mb-4 pt-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                                <div class="text-center">
                                    <span class="text-muted d-block mb-2">Dinero en caja</span>
                                    <div>
                                        <input 
                                        id= "amount"
                                        type="text" 
                                        className="form-control mb-3 mx-auto"
                                        value={this.formatNumber(this.state.amount)}
                                        onChange={this.handleChange} 
                                        placeholder="$ 0.0" 
                                        style={{ fontSize: '24px', textAlign: 'center'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                         </div>
                    <div className="row align-items-center justify-content-center">
                        <button onClick={() => this.openCashRegister()} type="button" className="mb-2 btn btn-primary mr-2" style={{float: 'right', fontSize: '25px'}} disabled={this.props.disabled}>Abrir Caja</button>
                    </div>
                                 
                    
                </div>
      
    );
  }
}

export default OpenCashComponent;
