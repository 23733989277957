import React, { Component } from 'react';

class GridImagesComponent extends Component {

	constructor(){
        super();
        this.state = {
            items: [1, 2, 3, 4, 5, 6]
        }
	}

    onChangePosition = () => {
        var pos_img_1 = document.getElementById("select-1").value;
        var pos_img_2 = document.getElementById("select-2").value;
        var pos_img_3 = document.getElementById("select-3").value;
        var pos_img_4 = document.getElementById("select-4").value;
        var pos_img_5 = document.getElementById("select-5").value;
        var pos_img_6 = document.getElementById("select-6").value;
        var newList = [pos_img_1, pos_img_2, pos_img_3, pos_img_4, pos_img_5, pos_img_6];
        var newListSinRep = newList.filter(function(item, index, array) {
            return array.indexOf(item) === index;
        })
        if (newListSinRep.length < this.state.items.length) {
            alert("Tienes elementos repetidos")
        }

        var newMapImages = this.props.images;
        for (var i=1; i<=6; i++) {
            var pos = 1;
            if (i == 1) {
                pos = pos_img_1;
            } else if (i == 2) {
                pos = pos_img_2;
            } else if (i == 3) {
                pos = pos_img_3;
            } else if (i == 4) {
                pos = pos_img_4;
            } else if (i == 5) {
                pos = pos_img_5;
            } else if (i == 6) {
                pos = pos_img_6;
            }
            if (newMapImages[i] == undefined || newMapImages[i] == '') {
                newMapImages[i] = { path: '', position: pos}
            } else {
                newMapImages[i].position = pos;
            }
        }
        this.props.onUpdatePositions(newMapImages);
    }

    deleteImage = (position) => {
        var value = this.props.images[position]
        if (value != undefined && value != '') {
            this.props.onImageDeleted(position)
        }
    }

    getPathImage = (position) => {
        var path = "./images/empty-photo.png";
        var value = this.props.images[position]
        if (value != undefined && value != '' && value.path != '') {
            path = value.path;
        }
        return path;
    }

	render() {
        return (  
            <div>
                <div className="form-row mx-4">
                    <div className="form-group col-md-4">
                        <img id="image-1" style={{maxWidth: '100%', maxHeight: '100%'}} src={this.getPathImage(1)} alt="Image 1" />
                        <div className="form-row" style={{marginTop: '10px'}}>
                            <div className="form-group col-md-8">
                                <select id="select-1" className="custom-select" onChange={() => this.onChangePosition()}>
                                    <option value="0">None</option>
                                    <option value="1" selected>1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteImage(1)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <img id="image-2" style={{maxWidth: '100%', maxHeight: '100%'}} src={this.getPathImage(2)} alt="Image 2" />
                        <div className="form-row" style={{marginTop: '10px'}}>
                            <div className="form-group col-md-8">
                            <select id="select-2" className="custom-select" onChange={() => this.onChangePosition()}>
                                    <option value="0">None</option>
                                    <option value="1">1</option>
                                    <option value="2" selected>2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteImage(2)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <img id="image-3" style={{maxWidth: '100%', maxHeight: '100%'}} src={this.getPathImage(3)} alt="Image 3" />
                        <div className="form-row" style={{marginTop: '10px'}}>
                            <div className="form-group col-md-8">
                                <select id="select-3" className="custom-select" onChange={() => this.onChangePosition()}>
                                    <option value="0">None</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3" selected>3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteImage(3)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row mx-4">
                    <div className="form-group col-md-4">
                        <img id="image-4" style={{maxWidth: '100%', maxHeight: '100%'}} src={this.getPathImage(4)} alt="Image 4" />
                        <div className="form-row" style={{marginTop: '10px'}}>
                            <div className="form-group col-md-8">
                                <select id="select-4" className="custom-select" onChange={() => this.onChangePosition()}>
                                    <option value="0">None</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4" selected>4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteImage(4)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <img id="image-5" style={{maxWidth: '100%', maxHeight: '100%'}} src={this.getPathImage(5)} alt="Image 5" />
                        <div className="form-row" style={{marginTop: '10px'}}>
                            <div className="form-group col-md-8">
                                <select id="select-5" className="custom-select" onChange={() => this.onChangePosition()}>
                                    <option value="0">None</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5" selected>5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteImage(5)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-4">
                        <img id="image-6" style={{maxWidth: '100%', maxHeight: '100%'}} src={this.getPathImage(6)} alt="Image 6" />
                        <div className="form-row" style={{marginTop: '10px'}}>
                            <div className="form-group col-md-8">
                                <select id="select-6" className="custom-select" onChange={() => this.onChangePosition()}>
                                    <option value="0">None</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6" selected>6</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteImage(6)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GridImagesComponent;