export function compare(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result;
        try {
            result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
        } catch (error) {
            result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        }
        return result * sortOrder;
    }
}

export function updateArrows(e) {
    var value;
    if (e.target.className == "sorting") {
        value = "sorting_asc"
    } else if (e.target.className == "sorting_asc") {
        value = "sorting_desc"
    } else if (e.target.className == "sorting_desc") {
        value = "sorting_asc"
    }
    var x = document.getElementsByClassName("sorting_asc");
    for (var i = 0; i < x.length; i++) {
        x[i].className = "sorting";
    }
    var x = document.getElementsByClassName("sorting_desc");
    for (var i = 0; i < x.length; i++) {
        x[i].className = "sorting";
    }
    e.target.className = value
    
    return value;
}