import React, { Component } from 'react';

class HeaderComponent extends Component {

	constructor(){
        super();
	}

	render() {
        return (  
            <div class="page-header row no-gutters py-4">
                <div class="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
                    <span class="text-uppercase page-subtitle">{this.props.title}</span>
                    <h3 class="page-title">{this.props.subtitle}</h3>
                </div>
            </div>
        );
    }
}

export default HeaderComponent;