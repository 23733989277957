import React, { Component } from 'react';

var config = process.env;
var sha1 = require('sha1');

class CredentialsPage extends Component {

	constructor(){
        super();
        this.state = {
            role: 0,
            email: ""
        }
    }
    
    componentDidMount = () => {
        var token = this.props.location.search.replace("?token=", "")
        var request = {
            method: 'GET',
            headers: { 
                'access-token': token,
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/getSessionCredentials', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    email: data.data.email
                });
            }
        });
    }

    onClickSave = () => {
        var pass = document.getElementById("password");
        var rePass = document.getElementById("re-password");
        var hasError = false;
        if (pass.value.length == 0) {
            pass.className = "form-control is-invalid";
            hasError = true;
        } else {
            pass.className = "form-control";
        }
        if (pass.value != rePass.value) {
            rePass.className = "form-control is-invalid";
            hasError = true;
        } else {
            rePass.className = "form-control";
        }
        if (!hasError) {
            var token = this.props.location.search.replace("?token=", "")
            var passwordEncrypted = sha1(pass.value);
            var request = {
                method: 'POST',
                headers: { 
                    'access-token': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: passwordEncrypted
                })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'admin/setCredentials', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                } else {
                    window.location.href = "/";
                }
            });
        }
    }
    
	render() {
        return (  
            <div className="main-content-container container-fluid px-4 my-auto">
                <div className="row no-gutters">
                    <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto">
                        <div className="card" style={{marginTop: '150px'}}>
                            <div className="card-body">
                                <img className="d-table mx-auto mb-3" src="./images/logo.png" style={{maxWidth: '9rem'}} />
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" value={this.state.email} disabled/>
                                </div>
                                <div className="form-group">
                                    <label for="password">Contraseña</label>
                                    <input type="password" className="form-control" id="password" placeholder="Nueva contraseña" />
                                </div>
                                <div className="form-group">
                                    <label for="re-password">Repetir contraseña</label>
                                    <input type="password" className="form-control" id="re-password" placeholder="Repetir nueva contraseña" />
                                </div>
                                <button type="submit" className="btn btn-pill btn-accent d-table mx-auto" onClick={() => this.onClickSave()}>Guardar</button>
                            </div>
                        </div>
                        <div className="auth-form__meta d-flex mt-4">
                            <a class="mx-auto" href="/">Volver para iniciar sesión</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CredentialsPage;