import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import AnalyticsComponent from '../components/AnalyticsComponent';
import NavbarComponent from '../components/NavbarComponent';
import { validateSession } from '../utils/functions';

class ProductAnalyticsPage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={1} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <AnalyticsComponent 
                                code={ this.props.location.search.replace("?code=", "") }                                
                                role={this.state.role}
                            />
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default ProductAnalyticsPage;