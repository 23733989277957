import React, { Component } from 'react';
import { convertToMoney } from '../utils/formats';

class PurchasePaymentComponent extends Component {

    constructor(){
        super();
        this.state = {
            method: 5, //1:efectivo | 2:transferencia | 3:mercadopago
            promo_code: ""
        }
    }

    componentDidMount() {
        const promoCode = document.getElementById('promo_code');
        promoCode.addEventListener('focusout', (event) => {
            var data = {};
            data["method"] = this.state.method;
            data["promo_code"] = promoCode.value;
            this.setState({promo_code: promoCode.value})
            this.props.onChangeData(data);
        });
    }

    onChangeInput = (input) => {
        var data = {};
        data["method"] = this.state.method;
        data["promo_code"] = this.state.promo_code;

        var id = input.target.id;
        if (id == "efectivo") {
            document.getElementById("transferencia").checked = false;
            document.getElementById("mercadopago").checked = false;
            document.getElementById("mercadolibre").checked = false;
            document.getElementById("pos").checked = false;
            data["method"] = 1;
            this.setState({method: 1})
        } else if (id == "transferencia") {
            document.getElementById("efectivo").checked = false;
            document.getElementById("mercadopago").checked = false;
            document.getElementById("mercadolibre").checked = false;
            document.getElementById("pos").checked = false;
            data["method"] = 2;
            this.setState({method: 2})
        } else if (id == "mercadopago") {
            document.getElementById("efectivo").checked = false;
            document.getElementById("transferencia").checked = false;
            document.getElementById("mercadolibre").checked = false;
            document.getElementById("pos").checked = false;
            data["method"] = 3;
            this.setState({method: 3})
        } else if (id == "mercadolibre") {
            document.getElementById("efectivo").checked = false;
            document.getElementById("transferencia").checked = false;
            document.getElementById("mercadopago").checked = false;
            document.getElementById("pos").checked = false;
            data["method"] = 4;
            this.setState({method: 4})
        } else if (id == "pos") {
            document.getElementById("efectivo").checked = false;
            document.getElementById("transferencia").checked = false;
            document.getElementById("mercadopago").checked = false;
            document.getElementById("mercadolibre").checked = false;
            data["method"] = 5;
            this.setState({method: 5})
        }
        this.props.onChangeData(data);
    }

    render() {
        return (
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <span>Medio de pago</span>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="efectivo" name="efectivo" class="custom-control-input" onClick={(e) => this.onChangeInput(e)}/>
                                <label class="custom-control-label" for="efectivo">Efectivo</label>
                            </div>
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="transferencia" name="transferencia" class="custom-control-input" onClick={(e) => this.onChangeInput(e)}/>
                                <label class="custom-control-label" for="transferencia">Transferencia</label>
                            </div>
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="mercadopago" name="mercadopago" class="custom-control-input" onClick={(e) => this.onChangeInput(e)}/>
                                <label class="custom-control-label" for="mercadopago">Mercado Pago</label>
                            </div>
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="mercadolibre" name="mercadolibre" class="custom-control-input" onClick={(e) => this.onChangeInput(e)}/>
                                <label class="custom-control-label" for="mercadolibre">Mercado Libre</label>
                            </div>
                            <div class="custom-control custom-radio mb-1">
                                <input type="radio" id="pos" name="pos" class="custom-control-input" onClick={(e) => this.onChangeInput(e)} defaultChecked="true"/>
                                <label class="custom-control-label" for="pos">POS</label>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Promo code</label>
                                    <input type="text" className="form-control" id="promo_code" placeholder="Promo code"/>
                                </div>
                                <div className="form-group col-md-6" style={{textAlign: 'right'}}>
                                    <label>Total</label>
                                    <div style={{marginTop: '13px'}}>
                                        <strong>$ <span id="total_price_discount">{convertToMoney(0)}</span> <del><span style={{fontWeight: '300', fontSize: '12px', marginLeft: '5px'}} id="total_price"></span></del></strong>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default PurchasePaymentComponent;