import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import FormAccessoryComponent from '../components/FormAccessoryComponent';
import firebase from '@firebase/app';
import { validateSession } from  '../utils/functions';
import '@firebase/storage';

var config = process.env;

class EditAccessoryPage extends Component {

	constructor(){
        super();
        this.state = {
            uploadValue: 0,
            checked: false,
            disabled: false,
            role: 0
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    handleChangeData = (value) => {
        this.setState({ disabled: true });
        var token = localStorage.getItem('token');
        var request = {
            method: 'PATCH',
            headers: { 
            'Content-Type': 'application/json',
            'access-token': token
            },
            body: JSON.stringify({
                prefix: value.prefix,
                code: parseInt(value.code),
                name: value.name,
                price: parseFloat(value.price),
                discount: parseInt(value.discount),
                stock: parseInt(value.stock),
                minStock: parseInt(value.minStock),
                suggestionStock: parseInt(value.suggestionStock),
                description: value.description,
                type: parseInt(value.type),
                provider: parseInt(value.provider),
                relatedProducts: value.relatedProducts,
                externalItems: value.externalItems,
                sku: value.sku,
                barCode: parseInt(value.barCode),
                cost: parseInt(value.cost),
                display: (value.display === 'true'),
                codeML: value.codeML
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'accessory', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                console.log(data)
                alert(data.message);
                this.setState({ disabled: false });
            } else {
                this.setState({ uploadValue: 5 });
                this.deleteImages(value.images, value.code);
            }
        });
    }

    deleteImages = (images, code) => {
        const ref = firebase.storage().ref(`/accessories/${code}`);
        ref.listAll().then(dir => {
            let listImagesToDelete = [];
            dir.items.forEach(item => {
                listImagesToDelete.push(item.fullPath);
            });
            if (listImagesToDelete.length === 0) {
                this.uploadImages(images, code);
            } else {
                this.setState({ imagesToDelete: listImagesToDelete.length })
                listImagesToDelete.forEach(item => {
                    this.deleteFile(item, images, code);
                })
            }
        });
    }

    deleteFile = (pathToFile, images, code) => {
        firebase.storage().ref(pathToFile).delete()
        .then(() => {
            var pending = this.state.imagesToDelete;
            pending -= 1;
            if (pending == 0) {
                this.uploadImages(images, code);
            } else {
                this.setState({ imagesToDelete: pending });
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    uploadImages = (images, code) => {
        var percentage = 40;
        var imagesUploaded = 0;
        var imagesToUpload = 0;
        Object.keys(images).forEach((key) => {
            if (images[key] != undefined && images[key] != '' && images[key].path != '') {
                imagesToUpload += 1;
            }
        });
        if (imagesUploaded === imagesToUpload) {
            this.redirect();
        } else {
            this.setState({ uploadValue: 40 });
            Object.keys(images).forEach((key) => {
                var image = images[key];
                if (image != undefined && image != '' && image.path != '') {
                    fetch(image.path)
                    .then(res => res.blob())
                    .then(blob => {
                        const storageRef = firebase.storage().ref(`/accessories/${code}/${image.position}`);
                        const task = storageRef.put(blob);
                        task.on('state_changed', (snapshot) => {}, (error) => {
                            console.log(error);
                        }, () => {
                            imagesUploaded += 1;
                            this.setState({ uploadValue: percentage });
                            percentage = (imagesUploaded / imagesToUpload) * 50 + 50;
                            if (percentage >= 100) {
                                percentage = 90;
                            } else if (percentage <= 50) {
                                percentage = 60;
                            }
                            if (imagesUploaded === imagesToUpload) {
                                this.redirect();
                            }
                        });
                    });
                }
            });
        }    
    }

    redirect = () => {
        window.location.href = "/accessories"
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={5} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <FormAccessoryComponent 
                                code={ this.props.location.search.replace("?code=", "") }
                                onChangeData={ this.handleChangeData } 
                                uploadValue={ this.state.uploadValue }
                                disabled={this.state.disabled}
                                role={this.state.role}
                            />
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default EditAccessoryPage;