import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import GeneralStatisticsComponent from '../components/GeneralStatisticsComponent';
import { validateSession } from  '../utils/functions';

class GeneralStatistics extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={1} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="col-md-12 page-header page-title d-flex justify-content-center mb-4 mt-4">  
                                <h3>Ventas por mes</h3>
                            </div>  
                            <GeneralStatisticsComponent/>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default GeneralStatistics;