import React, { Component } from 'react';

class SidebarComponent extends Component {

	constructor(){
        super();
	}

	render() {
        return (  
            <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0" style={{zIndex: 1000}}>
                <div className="main-navbar">
                    <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
                        <a className="navbar-brand w-100 mr-0" href="#" style={{lineHeight: '25px'}}>
                            <div className="d-table m-auto">
                                <img id="main-logo" className="d-inline-block align-top mr-1" style={{maxWidth: '100px'}} src="images/logo.png" alt="EnigmaGames" />
                            </div>
                        </a>
                    </nav>
                </div>

                <div className="nav-wrapper">
                    <ul className="nav flex-column">
                        <h6 className="main-sidebar__nav-title" style={{background: '#f9f9f9'}}>General</h6>
                        {this.props.role == 1 ? (<li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 1) ? 'active' : ''}`} href="/generalStatistics">
                                <i class="material-icons">insert_chart_outlined</i><span>Estadísticas generales</span>
                            </a>
                        </li>) : null
                        }
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 12) ? 'active' : ''}`} href="/caja">
                                <i class="material-icons">attach_money</i><span>Caja</span>
                            </a>
                        </li>
                        <li className="nav-item" style={{display: (this.props.role == 1) ? 'block' : 'none'}}>
                            <a className={`nav-link ${(this.props.itemSelected == 14) ? 'active' : ''}`} href="/movements">
                                <i class="material-icons">paid</i><span>Movimientos</span>
                            </a>
                        </li>
                        {this.props.role == 1 ? (<li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 17) ? 'active' : ''}`} href="/invoices">
                                <i class="material-icons">receipt</i><span>Facturas</span>
                            </a>
                        </li>) : null
                        }
                        <h6 className="main-sidebar__nav-title" style={{background: '#f9f9f9'}}>Compras</h6>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 8) ? 'active' : ''}`} href="/purchase">
                                <i class="material-icons">shopping_cart</i><span>Ventas</span>
                            </a>
                        </li>
                        <li className="nav-item" style={{display: (this.props.role == 1) ? 'block' : 'none'}}>
                            <a className={`nav-link ${(this.props.itemSelected == 6) ? 'active' : ''}`} href="/tickets">
                                <i class="material-icons">article</i><span>Tickets</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 9) ? 'active' : ''}`} href="/shipping">
                                <i class="material-icons">local_shipping</i><span>Envios</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 11) ? 'active' : ''}`} href="/discountCodes">
                                <i class="material-icons">view_module</i><span>Códigos de descuento</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 16) ? 'active' : ''}`} href="/soldProducts">
                                <i className="material-icons">history</i><span>Productos vendidos</span>
                            </a>
                        </li>
                        <h6 className="main-sidebar__nav-title" style={{background: '#f9f9f9'}}>Productos</h6>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 4) ? 'active' : ''}`} href="/boxGames">
                                <i className="material-icons">view_module</i><span>Juegos de caja</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 5) ? 'active' : ''}`} href="/accessories">
                                <i className="material-icons">view_module</i><span>Accesorios</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 13) ? 'active' : ''}`} href="/restocking">
                                <i className="material-icons">add_circle_outline</i><span>Reposición de stock</span>
                            </a>
                        </li>
                        <h6 className="main-sidebar__nav-title" style={{background: '#f9f9f9'}}>Usuarios</h6>
                        <li className="nav-item" style={{display: (this.props.role == 1) ? 'block' : 'none'}}>
                            <a className={`nav-link ${(this.props.itemSelected == 7) ? 'active' : ''}`} href="/admin">
                                <i className="material-icons"></i><span>Administradores</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 3) ? 'active' : ''}`} href="/users">
                                <i className="material-icons">people</i><span>Clientes</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 10) ? 'active' : ''}`} href="/providers">
                                <i className="material-icons">people</i><span>Proveedores</span>
                            </a>
                        </li>                        
                        <h6 className="main-sidebar__nav-title" style={{background: '#f9f9f9'}}>Extras</h6>
                        <li className="nav-item">
                            <a className={`nav-link ${(this.props.itemSelected == 15) ? 'active' : ''}`} href="/labels">
                                <i className="material-icons">add_circle_outline</i><span>Etiquetas</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default SidebarComponent;