import React, { Component } from 'react';

class NotificationComponent extends Component {

	constructor(){
		super();
	}

    onClickViewAll = () => {
        alert("Checked");
    }

	render() {
        return (  
            <li className="nav-item border-right dropdown notifications">
                <a className="nav-link nav-link-icon text-center" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="nav-link-icon__wrapper">
                        <i className="material-icons">&#xE7F4;</i>
                        {/* <span className="badge badge-pill badge-danger">3</span> */}
                    </div>
                </a>
                <div className="dropdown-menu dropdown-menu-small" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" href="#">
                        <div className="notification__icon-wrapper">
                            <div className="notification__icon">
                                <i className="material-icons">&#xE6E1;</i>
                            </div>
                        </div>
                        <div className="notification__content">
                            <span className="notification__category">Analytics</span>
                            <p>Your website’s active users count increased by
                            <span className="text-success text-semibold">28%</span> in the last week. Great job!</p>
                        </div>
                    </a>
                    <a className="dropdown-item" href="#">
                        <div className="notification__icon-wrapper">
                            <div className="notification__icon">
                                <i className="material-icons">&#xE8D1;</i>
                            </div>
                        </div>
                        <div className="notification__content">
                            <span className="notification__category">Sales</span>
                            <p>Last week your store’s sales count decreased by
                            <span className="text-danger text-semibold">5.52%</span>. It could have been worse!</p>
                        </div>
                    </a>
                    <a className="dropdown-item notification__all text-center" href="" onClick={() => this.onClickViewAll()}> View all Notifications </a>
                </div>
            </li>
        );
    }
}

export default NotificationComponent;