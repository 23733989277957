import React, { Component } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import ModalPurchase from '../components/ModalPurchase';
import { TICKET_STATUS, TICKET_PAYMENT_METHOD, TICKET_INVOICE_STATUS } from '../utils/enums'
import { compare, updateArrows } from '../utils/table'
import { convertToMoney, getTextColorClassTicket, getTextColorClassShipping } from '../utils/formats'

var config = process.env;

class TableTicketComponent extends Component {

	constructor(){
        super();
        this.state = {
            purchaseId: 0,
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false,
            selectedTickets: []
        }
	}

	componentWillMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'ticket', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data,
                    loaded: true
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mode !== this.props.mode) {
            this.onChangeInput()
        }
    }

    handleCheckboxChange = (id) => {
        this.setState((prevState) => {
            const { selectedTickets } = prevState;
            let updatedselectedTickets;
    
            if (selectedTickets.includes(id)) {
                updatedselectedTickets = selectedTickets.filter(item => item !== id);
            } else {
                updatedselectedTickets = [...selectedTickets, id];
            }
    
            return {
                selectedTickets: updatedselectedTickets
            };
        }, () => {
            // Este callback se ejecuta después de que el estado se ha actualizado
            const totalSum = this.state.data
                .filter(item => this.state.selectedTickets.includes(item.id))
                .reduce((sum, item) => sum + item.totalPrice, 0);
    
            this.props.setSelectedTicketsAmmount(totalSum);
        });
    };

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay tickets</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    createModals = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=0; i<this.state.data.length; i++) {
                var data = this.state.data[i];
                items.push(
                    this.createModalChangeTrack(data.ticketId, data.status)
                );
                items.push(
                    this.createModalInitialPoint(data.ticketId, data.paymentData)
                );
            }
            return (
                <div>{ items }</div>
            );
        }
    }

    getItemsTicket = (currentStatus) => {
        let items = [];
        if (currentStatus == 2) {
            items.push(<option value="2">{ TICKET_STATUS[2] }</option>);
            items.push(<option value="3">{ TICKET_STATUS[3] }</option>);
        }
        else if (currentStatus == 3) {
            items.push(<option value="3">{ TICKET_STATUS[3] }</option>);
            items.push(<option value="2">{ TICKET_STATUS[2] }</option>);
        }
        return items;
    }

    createModalChangeTrack = (ticketId, currentStatus) => {
        return (
            <div className="modal fade" id={`modal-${ticketId}`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Cambiar estado del ticket</h4>
                        </div>
                        <div className="modal-body">
                            <p>¿Cual es el nuevo estado del ticket?</p>
                            <select id={`status-${ticketId}`} className="custom-select" defaultValue={currentStatus}>
                                { this.getItemsTicket(currentStatus) }
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal"
                                onClick={() =>  this.onUpdateItem(ticketId)}>
                                Actualizar
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createModalInitialPoint = (ticketId, paymentData) => {
        // let json = JSON.parse(paymentData);
        // if (json != null && json != undefined && json.initial_point != undefined) {
        //     return (
        //         <div className="modal fade" id={`modal-initialpoint-${ticketId}`} role="dialog">
        //             <div className="modal-dialog">
        //                 <div className="modal-content">
        //                     <div className="modal-header">
        //                         <h4 className="modal-title" style={{textAlign: "center"}}>Link de Mercado Pago</h4>
        //                     </div>
        //                     <div className="modal-body">
        //                         <a target="_blank" href={json.initial_point}>{ json.initial_point }</a>
        //                     </div>
        //                     <div className="modal-footer">
        //                         <button
        //                             className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
        //                             data-dismiss="modal">
        //                             Cancelar
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
    }

    onUpdateItem = (ticketId) => {
        var value = document.getElementById("status-" + ticketId).value;
        var request = {
            method: 'PATCH',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ticketId: parseInt(ticketId),
                status: parseInt(value)
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'ticket/status', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                //SI ESTA OK ACTUALIZO EN LA VISTA
                let row = document.getElementById(`item-status-${ticketId}`);
                row.innerHTML = TICKET_STATUS[parseInt(value)];
                row.classList = getTextColorClassTicket(parseInt(value));
                document.getElementById(`button-status-${ticketId}`).disabled = true;
            }
        });
    }
    
    onTicketClicked = (purchaseId) => {
        this.setState({ purchaseId: purchaseId })
    }

    generateButtonInitailPoint = (ticketId, paymentData, status) => {
        let json = JSON.parse(paymentData);
        if (json != null && json != undefined && json.initial_point != undefined) {
            return (
                <button 
                    id={`button-initialpoint-${ticketId}`}
                    data-toggle="modal"
                    data-target={`#modal-initialpoint-${ticketId}`}
                    type="button" 
                    disabled={status==2 || status==9 ? true : false}
                    class="btn btn-white"><i class="material-icons">link</i>
                </button>
            )
        }
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                {this.props.mode >=  1 ? (<td>
                    <input
                        type="checkbox"
                        checked={this.state.selectedTickets.includes(item.ticketId)}
                        onChange={() => this.handleCheckboxChange(item.ticketId)}
                    />
                </td>) : "" }
                <td tabindex="0" class="sorting_1">{item.ticketId}</td>
                <td id={`item-status-${item.ticketId}`} class={getTextColorClassTicket(item.status)}>{ TICKET_STATUS[item.status] }</td>
                <td data-toggle="modal" data-target={`#modal-view`} onClick={() => this.onTicketClicked(item.purchaseId)}><a href="#"> {item.purchaseId} </a></td>
                <td>$ { convertToMoney(item.totalPrice) }</td>
                <td>{ TICKET_PAYMENT_METHOD[item.paymentType] }</td>
                <td>{ TICKET_INVOICE_STATUS[item.invoiceStatus] }</td>
                <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                        <button 
                            id={`button-status-${item.ticketId}`}
                            data-toggle="modal"
                            data-target={`#modal-${item.ticketId}`}
                            type="button" 
                            disabled={item.status==2 || item.status==9 ? true : false}
                            class="btn btn-white"><i class="material-icons">edit</i>
                        </button>
                        { this.generateButtonInitailPoint(item.ticketId, item.paymentData, item.status) }
                    </div>
                </td>
            </tr>
        );
    }

    changeMasiveStatus = (status) => {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: parseInt(status),
                tickets: this.state.selectedTickets
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'ticket/invoice', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                window.location.href = '/tickets'
            }
        });
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        const input = document.getElementById("input_search").value.toLowerCase();
    
        // Filtramos la lista basándonos en la coincidencia con cualquier criterio
        const newList = this.state.data.filter(item => 
            [item.ticketId, item.purchaseId, item.totalPrice, 
             TICKET_STATUS[item.status], TICKET_PAYMENT_METHOD[item.paymentType], 
             TICKET_INVOICE_STATUS[item.invoiceStatus]]
            .some(field => field.toString().toLowerCase().includes(input))
        );
    
        // Filtramos por invoiceStatus si el modo es 1
        const filteredList = this.props.mode === 1 
            ? newList.filter(item => item.invoiceStatus === 0) 
            : newList;
    
        // Actualizamos el estado con los datos filtrados
        this.setState({ filterData: filteredList });
    };

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            {this.props.mode >= 1 ? (<th className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}} aria-sort="ascending">Select</th>) : "" }
                            <th onClick={(e) => this.orderData(e, "ticketId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}} aria-sort="ascending">ID</th>
                            <th onClick={(e) => this.orderData(e, "status")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}}>Estado</th>
                            <th onClick={(e) => this.orderData(e, "purchaseId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Compra</th>
                            <th onClick={(e) => this.orderData(e, "totalPrice")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Precio</th>
                            <th onClick={(e) => this.orderData(e, "paymentType")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Metodo de pago</th>
                            <th onClick={(e) => this.orderData(e, "invoiceStatus")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Factura</th>
                            <th rowspan="1" colspan="1" style={{width: '10%'}}>Acciones</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                { this.createModals() }

                { <ModalPurchase purchaseId={this.state.purchaseId}/> }

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableTicketComponent;