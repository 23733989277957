import React, { Component } from 'react';

class PaginationComponent extends Component {

	constructor(){
        super();
        this.state = {
            pageSelected: 1
		}
	}

    componentDidUpdate(prevProps) {
        if (prevProps.totalItems != this.props.totalItems || prevProps.quantityRows != this.props.quantityRows) {
            this.setState({pageSelected: 1});
            this.updateData(1);
        }
    }

    calculateShowFrom = () => {
        return ((this.state.pageSelected-1)*this.props.quantityRows + 1);
    }

    calculateShowTo = () => {
        var to = this.state.pageSelected*this.props.quantityRows;
        if (to > this.props.totalItems) {
            to = this.props.totalItems;
        }
        return to;
    }

    calculateQuantityOfPages = () => {
        var div = Math.trunc(this.props.totalItems / this.props.quantityRows);
        var rem = this.props.totalItems % this.props.quantityRows;
        var pages = div;
        if (rem > 0) {
            pages += 1;
        }
        return pages;
    }

    onClickButtonNext = () => {
        var page = parseInt(this.state.pageSelected);
        var quantity = parseInt(this.calculateQuantityOfPages());
        if (page < quantity) {
            page += 1;
            this.setState({pageSelected: page});
            this.updateData(page);
        }
    }

    onClickButtonPrev = () => {
        var page = parseInt(this.state.pageSelected);
        if (page > 1) {
            page -= 1;
            this.setState({pageSelected: page});
            this.updateData(page);
        }
    }

    onClickButton (e) {
        if (this.state.pageSelected != e.target.id) {
            this.setState({pageSelected: e.target.id});
            this.updateData(e.target.id);
        }
    }

    updateData = (pageSelected) => {
        var from = ((pageSelected-1) * this.props.quantityRows + 1);
        var to = pageSelected * this.props.quantityRows;
        if (to > this.props.totalItems) {
            to = this.props.totalItems;
        }
        this.props.onChangeData(from, to)
    }

    renderItemsButtons = () => {
        var quantity = this.calculateQuantityOfPages();
        var listItems = [];
        if (quantity <= 7) {
            listItems = [];
            for (var i = 1; i <= quantity; i++) {
                listItems.push(
                    <a id={i} onClick={(e) => this.onClickButton(e) } className={`paginate_button ${this.state.pageSelected == i ? 'current' : ''}`} aria-controls="DataTables_Table_0" data-dt-idx={i} tabindex="0">{ i }</a>
                );
            }
        }
        else if (this.state.pageSelected < 5) {
            listItems = [];
            for (var i = 1; i <= 5; i++) {
                listItems.push(
                    <a id={i} onClick={(e) => this.onClickButton(e) } className={`paginate_button ${this.state.pageSelected == i ? 'current' : ''}`} aria-controls="DataTables_Table_0" data-dt-idx={i} tabindex="0">{ i }</a>
                );
            }
            listItems.push(<span class="ellipsis">…</span>);
            listItems.push(<a id={quantity} onClick={(e) => this.onClickButton(e) } className="paginate_button" aria-controls="DataTables_Table_0" data-dt-idx={quantity} tabindex="0">{ quantity }</a>);
        }
        else if (this.state.pageSelected > quantity - 4) {
            listItems = [];
            listItems.push(<a id={1} onClick={(e) => this.onClickButton(e) } className="paginate_button" aria-controls="DataTables_Table_0" data-dt-idx={1} tabindex="0">{ 1 }</a>);
            listItems.push(<span class="ellipsis">…</span>);
            for (var i = (quantity - 4); i <= quantity; i++) {
                listItems.push(
                    <a id={i} onClick={(e) => this.onClickButton(e) } className={`paginate_button ${this.state.pageSelected == i ? 'current' : ''}`} aria-controls="DataTables_Table_0" data-dt-idx={i} tabindex="0">{ i }</a>
                );
            }
        }
        else {
            listItems = [];
            listItems.push(<a id={1} onClick={(e) => this.onClickButton(e) } className="paginate_button" aria-controls="DataTables_Table_0" data-dt-idx={1} tabindex="0">{ 1 }</a>);
            listItems.push(<span class="ellipsis">…</span>);
            listItems.push(<a id={this.state.pageSelected -1} onClick={(e) => this.onClickButton(e) } className="paginate_button" aria-controls="DataTables_Table_0" data-dt-idx={this.state.pageSelected - 1} tabindex="0">{ this.state.pageSelected - 1 }</a>);
            listItems.push(<a id={this.state.pageSelected} onClick={(e) => this.onClickButton(e) } className="paginate_button current" aria-controls="DataTables_Table_0" data-dt-idx={this.state.pageSelected} tabindex="0">{ this.state.pageSelected }</a>);
            listItems.push(<a id={this.state.pageSelected + 1} onClick={(e) => this.onClickButton(e) } className="paginate_button" aria-controls="DataTables_Table_0" data-dt-idx={this.state.pageSelected + 1} tabindex="0">{ this.state.pageSelected + 1 }</a>);
            listItems.push(<span class="ellipsis">…</span>);
            listItems.push(<a id={quantity} onClick={(e) => this.onClickButton(e) } className="paginate_button" aria-controls="DataTables_Table_0" data-dt-idx={quantity} tabindex="0">{ quantity }</a>);
        }
        return(<span> { listItems } </span>);
    }

    renderButtons = () => {
        return (
            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                <a className={`paginate_button previous ${this.state.pageSelected == 1 ? 'disabled' : ''}`} onClick={() => this.onClickButtonPrev() } aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" id="DataTables_Table_0_previous">Anterior</a>
                { this.renderItemsButtons() }
                <a className={`paginate_button next ${this.state.pageSelected == this.calculateQuantityOfPages() ? 'disabled' : ''}`} onClick={() => this.onClickButtonNext() } aria-controls="DataTables_Table_0" data-dt-idx="7" tabindex="0" id="DataTables_Table_0_next">Siguiente</a>
            </div>
        );
    }

	render() {
        return (  
            <div>
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Mostrando {this.calculateShowFrom()} a {this.calculateShowTo()} de {this.props.totalItems} items
                </div>
                { this.renderButtons() }
            </div>
        );
    }
}

export default PaginationComponent;