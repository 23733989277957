import React, { Component } from 'react';
import { convertToMoney } from '../utils/formats'
import { MOVEMENTS_CURRENCY } from '../utils/enums'

var config = process.env;

class PriceAccountComponent extends Component {

	constructor(){
        super();
        this.state = {
            data: []
		}
	}

	componentWillMount() {
        this.fetchData();
    }

    fetchData = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'accounts', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({ data: data.data });
            }
        });
    }

    renderCard = () => {
        var items = [];
        this.state.data.forEach(item => {
            items.push(
                <div class="col-md-3">
                    <div class="card card-small mb-4 pt-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                        <div class="text-center">
                            <div class="mb-3 mx-auto" style={{ fontSize: '24px'}}>{ MOVEMENTS_CURRENCY[item.currency] } { convertToMoney(item.amount) }</div>
                            <span class="text-muted d-block mb-2">{ item.name }</span>
                        </div>
                    </div>
                </div>
            );
        });
        return(<div class="row">{ items }</div>);
    }

	render() {
        return (  
            this.renderCard()
        );
    }
}

export default PriceAccountComponent;