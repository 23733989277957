import React, { Component } from 'react';

import PaginationComponent from './PaginationComponent';
import { compare, updateArrows } from '../utils/table'

import dateFormat from 'dateformat';

var config = process.env;

class TableSoldProductsComponent extends Component {

	constructor(){
        super();
        this.state = {
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false
		}
	}

	componentWillMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase/soldProductsByDate', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data,
                    loaded: true
                });
                
            }
        });
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="9" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay productos vendidos</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    getProducts = (item) => {
        if (item == '') {
            return "Todos";
        } else {
            return item;
        }
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.purchaseId + item.productName } style={{color: item.currentStock == 0 ? '#000' : '#5a6169' , background: item.currentStock == 0 ? '#ffb8b8' : (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td>{item.purchaseId}</td>
                <td>{ dateFormat(item.date, "dS mmmm, yyyy - HH:MM")  }</td>
                <td>{item.productName}</td>
                <td>{item.provider}</td>
                <td>{item.quantity}</td>
                <td>$ {item.price}</td>
                <td>{item.discount} %</td>
                <td>{item.currentStock}</td>                
                {this.props.role == 1 ? (
                    <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                        <button
                            onClick={() => this.onViewAnalytics(item.code)}
                            type="button"
                            className="btn btn-white">
                            <i className="material-icons">insert_chart_outlined</i>
                        </button>
                    </div>
                    </td>
                ) : null}
                
            </tr>
        );
    }

    onViewAnalytics = (code) => {
        window.location.href = `/productAnalytics?code=${code}`;
    }
    
    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = []
        for (var i=0; i<this.state.data.length; i++) {
            if (this.state.data[i].provider?.toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].productName?.toLowerCase().includes(input.toLowerCase())) {
                newList.push( this.state.data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "purchaseId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}} aria-sort="ascending">Venta</th>
                            <th onClick={(e) => this.orderData(e, "date")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Fecha</th>
                            <th onClick={(e) => this.orderData(e, "productName")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '25%'}}>Producto</th>
                            <th onClick={(e) => this.orderData(e, "provider")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Proveedor</th>
                            <th onClick={(e) => this.orderData(e, "quantity")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Cantidad</th>
                            <th onClick={(e) => this.orderData(e, "price")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Precio</th>
                            <th onClick={(e) => this.orderData(e, "discount")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Descuento</th>
                            <th onClick={(e) => this.orderData(e, "currentStock")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Stock actual</th>
                            {this.props.role == 1 ? (
                                <th tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1">Acciones</th>
                            ) : null}
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableSoldProductsComponent;