import React, { Component } from 'react';

import NotificationComponent from '../components/NotificationComponent';
import firebase from '@firebase/app';
import '@firebase/storage';

var config = process.env;

class NavbarComponent extends Component {

	constructor(){
        super();
        this.state = {
            showAlertTokenRefresh: false,
            src: "./images/empty-user.png",
            urlMP: "https://auth.mercadolibre.com.uy/authorization?response_type=code&client_id=" + config.REACT_APP_CLIENT_ID_MP + "&redirect_uri=" + config.REACT_APP_REDIRECT_MP
        }
    }
    
    componentDidMount = () => {
        this.loadProfileImage()
        // this.onLoadExpirationDate()
    }

    loadProfileImage = () => {
        var image = localStorage.getItem('image');
        if (image == undefined || image == '') {
            var email = localStorage.getItem('email');
            const ref = firebase.storage().ref(`/profile`);
            ref.listAll().then(dir => {            
                dir.items.forEach(item => {
                    if (email == item.name) {
                        firebase.storage().ref(item.fullPath).getDownloadURL().then((url) => {
                            var xhr = new XMLHttpRequest();
                            xhr.responseType = 'blob';
                            xhr.onload = (event) => {
                                var reader = new FileReader();
                                reader.onloadend = () => {
                                    localStorage.setItem('image', reader.result);
                                    this.setState({ src: url });
                                }
                                reader.readAsDataURL(xhr.response);
                            };
                            xhr.onerror = (event) => {
                                console.log(event);
                            };
                            xhr.open('GET', url);
                            xhr.send();
                        }).catch((error) => {
                            console.log(error);
                        })
                    }
                });
            })
            .catch(error => {
                console.log(error);
            });
        } else {
            this.setState({ src: image });
        }
    }

    onLoadExpirationDate = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'configuration/expires_in', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                var expiredIn = new Date(data.data[0]).getTime();
                var currentDate = new Date().getTime();
                var diff = expiredIn - currentDate;
                var diffInDays = diff/(1000*60*60*24);
                this.setState({
                    showAlertTokenRefresh: diffInDays < 15
                });
            }
        });
    }

    onClickLogout = (event) => {
        event.preventDefault(); // Evitar la acción predeterminada del evento de clic
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        return fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/logout', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                localStorage.clear();
                window.location.href = config.REACT_APP_REDIRECT_LOGIN;
            }
        });
    }

	render() {
        return (  
            <div className="main-navbar sticky-top bg-white">
                <nav className="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
                    <form action="#" className="main-navbar__search w-100 d-none d-md-flex d-lg-flex"></form>
                    <ul className="navbar-nav border-left flex-row ">
                        <NotificationComponent />

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-nowrap px-3" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                <img className="user-avatar rounded-circle mr-2" src={this.state.src} alt="User Avatar" />
                                <span className="d-none d-md-inline-block"> {localStorage.getItem('firstName')} {localStorage.getItem('lastName')} </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-small">
                                <a className="dropdown-item" href="/profile"><i className="material-icons">&#xE7FD;</i> Perfil</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href={this.state.urlMP}>
                                    Actualizar token MP
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item text-danger" href="" onClick={(event) => this.onClickLogout(event)}>
                                    <i className="material-icons text-danger">&#xE879;</i>Cerrar sesión
                                </a>
                            </div>
                        </li>
                    </ul>
                    <nav className="nav">
                        <a href="#" className="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center border-left" data-toggle="collapse" data-target=".header-navbar" aria-expanded="false" aria-controls="header-navbar">
                            <i className="material-icons">&#xE5D2;</i>
                        </a>
                    </nav>
                </nav>
                <div class="alert alert-danger" role="alert" style={{display: this.state.showAlertTokenRefresh ? 'block' : 'none'}}>Actualizar token MP</div>
            </div>
        );
    }
    
}

export default NavbarComponent;