import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import firebase from '@firebase/app';
import '@firebase/storage';

var config = process.env;

class ProfilePage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            disabled: false,
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            cropperImage: '',
            src: "./images/empty-user.png",
            empty: "./images/empty-user.png"
        }
    }
    
    async componentDidMount(){
        await this.validateSession();
        await this.loadData();
        var image = localStorage.getItem('image');
        if (image != undefined || image != '') {
            this.setState({ src: image, empty: image });
        }
    }

    validateSession = () => {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/checkSessionAdmin', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error == 0 && data.data.isValidToken) {
                this.setState({ checked: true, role: data.data.role });
            } else {
                window.location.href = config.REACT_APP_REDIRECT_LOGIN;
            }
        });
    }

    loadData = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'admin/profile', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                this.redirect();
            } else {
                this.setState({
                    firstName: data.data.firstName,
                    lastName: data.data.lastName,
                    phone: data.data.phone,
                    email: data.data.email
                });
            }
        });
    }

    redirect = () => {
        window.location.href = "/admin"
    }

    checkIsValid = (input) => {
        if (input.value == null || input.value == undefined || input.value == '') {
            input.className = "form-control is-invalid";
            return true;
        } else {
            input.className = "form-control";
            return false;
        }
    }

    onClickSave = () => {
        var firstName = document.getElementById("firstName");
        var lastName = document.getElementById("lastName");
        var email = document.getElementById("email");
        var phone = document.getElementById("phone");
        
        var hasError = false;
        hasError = this.checkIsValid(firstName) || hasError;
        hasError = this.checkIsValid(lastName) || hasError;
        hasError = this.checkIsValid(email) || hasError;
        hasError = this.checkIsValid(phone) || hasError;

        if (!hasError) {
            this.setState({ disabled: true });
            var token = localStorage.getItem('token');
            var request = {
                method: 'PATCH',
                headers: { 
                'Content-Type': 'application/json',
                'access-token': token
                },
                body: JSON.stringify({
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    phone: phone.value
                })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'admin', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    this.setState({ disabled: false });
                } else {
                    localStorage.setItem('firstName', firstName.value);
                    localStorage.setItem('lastName', lastName.value);
                    if (this.state.src != this.state.empty) {
                        localStorage.setItem('image', '');
                        this.deleteimage(email.value);
                    } else {
                        this.redirect();
                    }
                }
            });
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    deleteimage = (email) => {
        const ref = firebase.storage().ref(`/profile/${email}`);
        ref.listAll().then(dir => {            
            if (dir.items.length == 0) {
                this.uploadImage(email);
            } else {
                var path = dir.items[0].fullPath;
                firebase.storage().ref(path).delete().then(() => {
                    this.uploadImages(email);
                }).catch(function(error) {
                    console.log(error);
                });
            }
        });
    }

    uploadImage = (email) => {
        fetch(this.state.src)
        .then(res => res.blob())
        .then(blob => {
            const storageRef = firebase.storage().ref(`/profile/${email}`);
            const task = storageRef.put(blob);
            task.on('state_changed', (snapshot) => {
            }, (error) => {
                console.log(error);
            }, () => {
                this.redirect();
            });
        });
    }

    onCropperInit(cropper) {
        this.cropper = cropper;
    }

    cropImage = () => {
        if (typeof this.cropper.getCroppedCanvas() === "undefined" || this.cropper.getCroppedCanvas() === null) { return; }

        var url = this.cropper.getCroppedCanvas({
            maxWidth: 512,
            maxHeight: 512,
            imageSmoothingQuality: 'low'
        }).toDataURL('image/jpeg');

        this.setState({ src: url })
    }

    onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        try {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ cropperImage: reader.result });    
            };
            reader.readAsDataURL(files[0]);
        } catch (error) {}
    }

    handleClickFile = () => {
        document.getElementById("file").click();
    }

    handleClickModal = () => {
        document.getElementById("trigger-modal").click();
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={7} role={this.state.role}/>

                        <button id="trigger-modal" data-toggle="modal" data-target='#modal-cropper'></button>

                        <div className="modal fade" id='modal-cropper' role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" style={{textAlign: "center"}}>Recortar foto</h4>
                                    </div>
                                    <Cropper
                                        style={{ height: "500px", width: "100%", background: "#eaeaea"}}
                                        aspectRatio={1}
                                        guides={true}
                                        src={this.state.cropperImage}
                                        viewMode={1}
                                        dragMode="move"
                                        rotatable={true}
                                        cropBoxMovable={false}
                                        onInitialized={this.onCropperInit.bind(this)} />
                                    <div className="modal-footer">
                                        <button 
                                            onClick={() => this.handleClickFile()} 
                                            className="mb-2 btn btn-success mr-2 mr-auto" >
                                            Subir foto
                                        </button>
                                        <button 
                                            className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                            data-dismiss="modal">
                                            Cancelar
                                        </button>
                                        <button 
                                            className="mb-2 mr-1 btn btn-primary btn-sm" 
                                            data-dismiss="modal"
                                            onClick={() => this.cropImage()}>
                                            Confirmar
                                        </button>                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />                            
                            <div className="main-content-container container-fluid px-4 mb-4" style={{marginTop: '1.5rem'}}>
                                <HeaderComponent title="Dashboard" subtitle="Editar perfil"/>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card card-small mb-4 pt-3" style={{borderRadius: '.625rem', padding: '15px'}}>
                                            <div className="text-center">
                                                <div className="mb-3 mx-auto">
                                                    <img className="rounded-circle" src={this.state.src} alt="User Avatar" width="110" />
                                                </div>
                                                <span className="text-muted d-block mb-2">Imágen de perfil</span>
                                                <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={ this.onChange }/>
                                                <button 
                                                    onClick={() => this.handleClickModal()} 
                                                    type="button" 
                                                    style={{marginTop: '10px'}}
                                                    className="mb-2 btn btn-sm btn-pill btn-outline-primary mr-2">
                                                    <i class="material-icons mr-1">file_upload</i>
                                                    Subir
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Datos personales</h6>
                                            </div>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item p-3">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label for="firstName">Nombre</label>
                                                            <input type="text" className="form-control" id="firstName" placeholder="Nombre" placeholder="Ingrese nombre/s" defaultValue={this.state.firstName}/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label for="lastName">Apellido</label>
                                                            <input type="text" className="form-control" id="lastName" placeholder="Apellido" placeholder="Ingrese apellido/s" defaultValue={this.state.lastName}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label for="email">Email</label>
                                                            <input type="email" className="form-control" id="email" placeholder="Correo" placeholder="Ingrese el correo" defaultValue={this.state.email} disabled/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label for="phone">Teléfono</label>
                                                            <input type="text" className="form-control" id="phone" placeholder="Ingrese el telefono" defaultValue={this.state.phone}/>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer border-top">
                                                        <button onClick={() => this.onClickSave()} 
                                                                type="button" 
                                                                className="mb-2 btn btn-primary mr-2" 
                                                                style={{float: 'right'}} 
                                                                disabled={this.state.disabled}>
                                                                Guardar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default ProfilePage;