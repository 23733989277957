export function convertToMoney(price) {
    return Intl.NumberFormat("de-DE").format(price)
}

export function getTextColorClass(role) {
    var classStr = "text-success";
    if (role == 1) {
        classStr = "text-success";
    } else if (role == 9) {
        classStr = "text-error";
    } else {
        classStr = "text-warning";
    } 
    return classStr;
}

export function getTextColorClassShipping(status) {
    var classStr = "text-success";
    if (status == 4 || status == 6) {
        classStr = "text-warning";
    } else if (status == 9) {
        classStr = "text-error";
    } else {
        classStr = "text-success";
    } 
    return classStr;
}

export function getTextColorClassTicket(status) {
    var classStr = "text-success";
    if (status == 2) {
        classStr = "text-success";
    } else if (status == 9) {
        classStr = "text-error";
    } else {
        classStr = "text-warning";
    } 
    return classStr;
}

export function getTextColorClassStock(stock, minStock) {
    var classStr = "text-success";
    if (stock < minStock) {
        classStr = "text-danger";
    } else if (stock == minStock) {
        classStr = "text-warning";
    } 
    return classStr;
}

export function getTextColorClassGeneral(status) {
    var classStr = "text-success";
    if (status == 1 || status == 2 || status == 8) {
        classStr = "text-success";
    } else if (status == 9) {
        classStr = "text-error";
    } else {
        classStr = "text-warning";
    } 
    return classStr;
}

export function getTextColorRestockingPayment(status) {
    var classStr = "text-success";
    if (status == 1) {
        classStr = "text-success";
    } else {
        classStr = "text-warning";
    } 
    return classStr;
}