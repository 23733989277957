import React, { Component } from 'react';
import PaginationComponent from '../components/PaginationComponent';
import ModalPurchase from '../components/ModalPurchase';
import { TICKET_PAYMENT_METHOD } from '../utils/enums';
import dateFormat from 'dateformat';
import { GENERAL_STATUS } from '../utils/enums';
import { compare, updateArrows } from '../utils/table';
import { getTextColorClassGeneral, convertToMoney } from '../utils/formats';

var config = process.env;

class TablePurchaseComponent extends Component {

	constructor(){
        super();
        this.state = {
            purchaseId: 0,
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            bags: [],
            filterData: [],
            loaded: false
        }
	}

	componentWillMount() {
        //Si tengo id en url abro modal        
        var id = window.location.search;
        if (id.includes('?id=')){
            var value = id.replace("?id=", "")
            this.setState({ purchaseId: value })
        }
        this.fetchData();
        this.fetchBags();
    }

    fetchData = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data,
                    loaded: true
                });
                if (this.state.purchaseId > 0) {
                    document.getElementById("button-show-dialog").click()
                }
            }
        });
    }

    fetchBags = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'accessory/bags', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    bags: data.data
                });
            }
        });
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            var modals = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                    modals.push(this.createModalAddBag(this.state.filterData[i].purchaseId));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay ventas</td></tr>
                )
            }
            return (
                <tbody>{ items }{ modals }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    onClickModal = (purchaseId, status) => {
        this.setState({ purchaseId: purchaseId, status: status })
    }

    getItemsBags = () => {
        var list = [];
        this.state.bags.forEach( item => {
            list.push(<option value={item.code}>{ item.name }</option>);
        })
        return(list);
    }

    onClickAddBag = (purchaseId) => {
        var value = document.getElementById(`bag-${purchaseId}`).value;
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                purchaseId: purchaseId,
                bag: value
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase/bag', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                window.location.reload();
            }
        });
    }

    createModalAddBag = (purchaseId) => {
        return (
            <div className="modal fade" id={`modal-add-bag-${purchaseId}`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Agregar bolsa</h4>
                        </div>
                        <div className="modal-body">
                            <p>Seleccione una bolsa para esta compra</p>
                            <select id={`bag-${purchaseId}`} className="custom-select">
                                { this.getItemsBags() }
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal"
                                onClick={() =>  this.onClickAddBag(purchaseId)}>
                                Confirmar
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td tabindex="0" class="sorting_1">{item.purchaseId}</td>
                <td class={getTextColorClassGeneral(item.status)}>{ GENERAL_STATUS[item.status] }</td>
                <td>{item.firstName} {item.lastName}</td>
                <td>{ dateFormat(item.date, "dS mmmm, yyyy")  }</td>
                <td>{item.originator}</td>
                <td>{ TICKET_PAYMENT_METHOD[item.paymentType] }</td>
                <td>$ {convertToMoney(item.totalPrice)}</td>
                <td>                                       
                    {item.status != 10 ? (
                        <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions"> 
                            <button 
                                style={{ display: item.hasBag ? "none" : "block"}}
                                data-toggle="modal"
                                data-target={`#modal-add-bag-${item.purchaseId}`}
                                type="button" 
                                class="btn btn-white"><i class="material-icons">work_outline</i>
                            </button>
                            <button 
                                data-toggle="modal"
                                data-target={`#modal-view`}
                                onClick={ () => this.onClickModal(item.purchaseId, item.status) }
                                type="button" 
                                class="btn btn-white"><i class="material-icons">search</i>
                            </button>               
                            {/* <button 
                                onClick={ () => this.downloadInvoice(item.purchaseId) }
                                type="button" 
                                class="btn btn-white"><i class="material-icons">download</i>
                            </button> */}
                        </div>
                    ) : (
                        <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions"> 
                        <button 
                            data-toggle="modal"
                            data-target={`#modal-view`}
                            onClick={ () => this.onClickModal(item.purchaseId, item.status) }
                            type="button" 
                            class="btn btn-white"><i class="material-icons">add_shopping_cart</i>
                        </button>
                        </div>
                    )}                     
                </td>
            </tr>
        );
    }

    downloadInvoice = () => {
        //Fetch data
        var request = {
            method: 'GET'
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/1', request)
        .then(response => {


            //Create a Blob from the PDF Stream
                const file = new Blob(
                  [response.body], 
                  {type: 'application/pdf'});
            //Build a URL from the file
            console.log(file)
                const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
                window.open(fileURL);
            })
        
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = []
        for (var i=0; i<this.state.data.length; i++) {
            var fullName = `${this.state.data[i].firstName} ${this.state.data[i].lastName}`
            if (this.state.data[i].date.toLowerCase().includes(input.toLowerCase()) ||
                fullName.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].totalPrice.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].purchaseId.toString().toLowerCase().includes(input.toLowerCase()) ||
                GENERAL_STATUS[this.state.data[i].status].toString().toLowerCase().includes(input.toLowerCase())) {
                newList.push( this.state.data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        var value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "purchaseId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}} aria-sort="ascending">ID</th>
                            <th onClick={(e) => this.orderData(e, "status")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}}>Estado</th>
                            <th onClick={(e) => this.orderData(e, "fullName")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Nombre</th>
                            <th onClick={(e) => this.orderData(e, "date")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Fecha</th>
                            <th onClick={(e) => this.orderData(e, "originator")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Origen</th>
                            <th onClick={(e) => this.orderData(e, "method")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '12%'}}>Metodo</th>
                            <th onClick={(e) => this.orderData(e, "totalPrice")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '8%'}}>Precio</th>
                            <th rowspan="1" colspan="1" style={{width: '10%'}}>Acciones</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                { <ModalPurchase purchaseId={this.state.purchaseId} status={this.state.status}/> }

                <button 
                    id="button-show-dialog"
                    style={{display:"none"}}
                    data-toggle="modal"
                    data-target={`#modal-view`}
                    onClick={ () => this.onClickModal(this.state.purchaseId) }
                    type="button" 
                    class="btn btn-white"><i class="material-icons">search</i>
                </button>

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TablePurchaseComponent;