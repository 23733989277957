import React, { useState } from 'react';

function NumberInput({ id, value, onChange, name }) {
  const [inputValue, setInputValue] = useState('');

  // const handleKeyDown = (event) => {
  //   const value = event.target.value;
  //   const keyCode = event.keyCode || event.which;
  //   if (keyCode >= 48 && keyCode <= 57 || keyCode === 8) {
  //     setInputValue(value);
  //   } else {
  //     event.preventDefault();
  //   }
  // };

  return (
    <input
        id={id}
        type="text"
        class="form-control"
        // onKeyDown={handleKeyDown}
        placeholder="Ingrese un valor"
        value={value}
        name={name}
        onChange={onChange}
        style={{ borderRadius: 0, borderColor: "#ffffff00"}}
    />
  );
}

export default NumberInput;
