import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';

var config = process.env;

class RefreshTokenPage extends Component {

	constructor(){
        super();
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        this.state = {
            code: code
        }
	}

	componentWillMount() {
        this.validateSession();
    }
    
    validateSession = () => {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/checkSessionAdmin', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error == 0 && data.data.isValidToken) {
                if (data.data.role == 2) {
                    window.location.href = config.REACT_APP_REDIRECT_BOX_GAME;
                } else {
                    this.setState({ checked: true, role: data.data.role });
                    this.createToken();
                }
            } else {
                window.location.href = config.REACT_APP_REDIRECT_LOGIN;
            }
        });
    }

    createToken = () => {
        var request = {
            method: 'POST',
            headers: { 
              'content-type': 'application/x-www-form-urlencoded',
              'accept': 'application/json',
            },
            body: JSON.stringify({
                grant_type: 'authorization_code',
                client_id: config.REACT_APP_CLIENT_ID_MP,
                client_secret: config.REACT_APP_CLIENT_SECRET_MP,
                code: this.state.code,
                redirect_uri: config.REACT_APP_REDIRECT_MP
            })
        };        
        fetch('https://api.mercadolibre.com/oauth/token', request)
        .then(results => { return results.json(); })
        .then(data => {
            this.sendTokenToBackend(data)
        });
    }

    sendTokenToBackend = (data) => {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accessToken: data.access_token,
                refreshToken: data.refresh_token,
                expiresIn: data.expires_in
            })
        };
        console.log(request)

        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'configuration/updateToken', request)
        .then(results => { return results.json(); })
        .then(data => {
            window.location.href = config.REACT_APP_REDIRECT_LOGIN;
        });
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={8} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}></div>
                        </main>
                    </div>
                    
                    <div onClick={() => this.addPurchase()} class="color-switcher-toggle animated pulse infinite" style={{display: (this.state.role == 2) ? 'none' : 'block'}}>
                        <i class="material-icons">add</i>
                    </div>
                </div>
            );
        }
    }
}

export default RefreshTokenPage;