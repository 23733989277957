import React, { Component } from 'react';
import { convertToMoney } from '../utils/formats';

var config = process.env;

class PurchaseProductComponent extends Component {
    
    constructor(){
        super();
        this.state = {
            items: [],
            itemsFiltered: [],
            itemsSelected: {}
        }
        this.inputRef = React.createRef();
    }
  

    componentWillMount() {
        this.loadProducts();
    }

    componentDidMount() {
        this.inputRef.current.focus();
    }
    
    loadProducts = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product/all', request)
        .then(results => { return results.json(); })
        .then(data => {
            this.setState({items: data.data, itemsFiltered: data.data})
        });
    }
    
    onChangeItem = (e) => {
        var itemsSelected = this.state.itemsSelected;
        if (e.target.checked) {
            var item = undefined;
            this.state.items.forEach(element => {
                var code = `${element.prefix}${element.code}`
                if (code == e.target.id) {
                    item = {
                        prefix: element.prefix,
                        code: element.code,
                        name: element.name,
                        quantity: 1,
                        price: element.price,
                        discount: element.discount,
                        productType: element.productType
                    }
                }
            })
            itemsSelected[e.target.id] = item;
        } else {
            delete itemsSelected[e.target.id]
        }
        //Update parent and state
        this.props.onChangeData(itemsSelected);
        this.setState({itemsSelected: itemsSelected});
    }

    loadItems = () => {
        var items = [];
        for(var i=0; i<this.state.itemsFiltered.length; i++) {
            var data = this.state.itemsFiltered[i];
            if (data.stock > 0) {
                items.push(
                    <div class="custom-control custom-checkbox mb-1">
                        <input onChange={(e) => this.onChangeItem(e)} key={`${data.prefix}${data.code}`} type="checkbox" class="custom-control-input" id={`${data.prefix}${data.code}`} defaultChecked={`${data.prefix}${data.code}` in this.state.itemsSelected}/>
                        <label class="custom-control-label" for={`${data.prefix}${data.code}`}>{ data.name } ({data.stock})</label>
                    </div>
                );
            }
        }
        return (
            <div className="items">{ items }</div>
        );
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = [];
        for (var i=0; i<this.state.items.length; i++) {
            var name = this.state.items[i].name;
            var code = this.state.items[i].prefix + this.state.items[i].code;
            if (name.toLowerCase().includes(input.toLowerCase()) || code.toString().toLowerCase().includes(input.toLowerCase())) {
                newList.push( this.state.items[i] );
            }
        }
        this.setState({ itemsFiltered: newList });
    }

    drawModal = () => {
        return(
            <div className="modal fade" id={`modal-products`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Agregar producto</h4>
                            <div class="form-group" style={{marginBottom: '0'}}>
                                <input type="text" class="form-control" id="input_search" placeholder="Buscar" onChange={() => this.onChangeInput()}/>
                            </div>
                        </div>
                        <div className="modal-body" style={{maxHeight: '70vh', overflowY: 'auto'}}>
                            { this.loadItems() }
                        </div>                        
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cerrar
                            </button>                      
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    drawTable = () => {
        return (
            <table id="add-purchase-table" style={{width: '100%'}}>
                <thead>
                    <tr role="row">
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '10%'}}>Código</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '30%'}}>Nombre</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '10%'}}>Cantidad</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '15%'}}>Precio</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '15%'}}>Descuento</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '20%'}}>Precio final</th>
                    </tr>
                </thead>                
                { this.drawTableItems() }
            </table>
        );
    }

    drawTableItems = () => {
        var map = this.state.itemsSelected;
        var index = 0;
        var items = [];
        for (const [key, value] of Object.entries(map)) {
            items.push(this.drawRow(key, value, index));
            index++;
        }
        if (index == 0) {
            items.push(
                <tr><td colspan="6" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No tienes elementos</td></tr>
            )
        }
        return (<tbody>{ items }</tbody>)
    }

    upQuantity = (code) => {
        var products = this.state.itemsSelected;
        var quantity = parseInt(products[code].quantity);
    
        var product;
        this.state.items.forEach(item => {
            var tempCode = item.prefix + item.code
            if (tempCode == code) {
                product = item;
            }
        });

        if (product != undefined && quantity < product.stock) {
            quantity += 1;
            products[code].quantity = quantity;
            this.props.onChangeData(products);
            this.setState({itemsSelected: products});
        }
    }

    downQuantity = (code) => {
        var products = this.state.itemsSelected;
        var quantity = parseInt(products[code].quantity);
        quantity -= 1;
        if (quantity > 0) {
            products[code].quantity = quantity;
            this.props.onChangeData(products);
            this.setState({itemsSelected: products});
        }
    }

    drawRow = (code, item, index) => {
        return(
            <tr role="row" class="odd table-purchase-item" key={ code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td class="table-purchase-item">{code}</td>
                <td class="table-purchase-item">{item.name}</td>      
                <td class="table-purchase-item">
                    <div class="row" style={{marginLeft: '0', marginRight: '0'}}>
                        <div class="col" style={{padding: '0', cursor: 'pointer'}} onClick={() => this.downQuantity(code)}>
                            <span class="input-group-text" style={{padding: '4px'}}>-</span>
                        </div>
                        <div class="col" style={{marginTop: '2px'}}>
                            {item.quantity}
                        </div>
                        <div class="col" style={{padding: '0', cursor: 'pointer'}} onClick={() => this.upQuantity(code)}>
                            <span class="input-group-text" style={{padding: '4px'}}>+</span>
                        </div>
                    </div>
                </td>
                <td class="table-purchase-item">{convertToMoney(item.price)}</td>
                <td class="table-purchase-item">{item.discount}</td>
                <td class="table-purchase-item">{convertToMoney(item.quantity * item.price * (1 - (item.discount / 100)))}</td>
            </tr>
        );
    }

    // Función de debounce
    debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    // Función para manejar el cambio en el input con debounce
    handleBarcodeChange = this.debounce((event) => {
        const barCode = event.target.value;
        if (barCode != null && barCode != undefined && barCode != 0 && barCode != "")
        {
            var itemsSelected = this.state.itemsSelected;
            var item = undefined;
            this.state.items.forEach(element => {
                if (element.barCode == barCode) {
                    if (itemsSelected[element.prefix+element.code] != null)
                    {
                        let quantity = itemsSelected[element.prefix+element.code].quantity
                        item = {... itemsSelected[element.prefix+element.code] , quantity : quantity +1}
                    }
                    else{
                        item = {
                            prefix: element.prefix,
                            code: element.code,
                            name: element.name,
                            quantity: 1,
                            price: element.price,
                            discount: element.discount,
                            productType: element.productType
                        }
                    }                   
                    itemsSelected[element.prefix+element.code] = item;
                    this.props.onChangeData(itemsSelected);
                    this.setState({itemsSelected: itemsSelected});
                    document.getElementById("barCode").value = "";
                }
            })
            
        }        
    }, 150);

    render() {
        return (
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom ">
                        <div className="row">
                            <span className="col-lg-4">Productos</span> 
                            <input autoComplete="off" className="col-lg-4 form-control" type="text" id="barCode" placeholder="Código de barras" ref={this.inputRef} onChange={(e) => this.handleBarcodeChange(e)}/>
                            <button data-toggle="modal" data-target={`#modal-products`} type="button" className="btn btn-outline-primary col-lg-4" style ={{float: 'right'}}>Agregar</button>
                         </div>
                       </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                            { this.drawTable() }
                        </li>
                    </ul>
                </div>
                { this.drawModal() }
            </div>
        );
    }
}

export default PurchaseProductComponent;