import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import TableSoldProductsComponent from '../components/TableSoldProductsComponent';
import { validateSession } from  '../utils/functions';
var config = process.env;

class SoldProductsPage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={16} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Ventas" subtitle="Productos vendidos"/>
                                <TableSoldProductsComponent role={this.state.role} />
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default SoldProductsPage;