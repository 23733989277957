import React, { Component } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import { compare, updateArrows } from '../utils/table'
import { validateSession } from  '../utils/functions';

var config = process.env;

class TableProvidersComponent extends Component {

	constructor(){
        super();
        this.state = {
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false,
            role: 0
		}
	}

	async componentDidMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'provider/product/complete', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data,
                    loaded: true
                });
            }
        });
        let role = await validateSession()
        this.setState({ role:  role});
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay proveedores</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.providerId } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td tabindex="0" class="sorting_1">{item.providerId}</td>
                <td>{item.name}</td>
                {this.state.role == 1 ? <td style={{ textAlign: "right" }}>$ {item.debt}</td> : ""}
            </tr>
        );
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = []
        for (var i=0; i<this.state.data.length; i++) {
            if (this.state.data[i].providerId.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].name.toString().toLowerCase().includes(input.toLowerCase())
            ) {
                newList.push( this.state.data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "providerId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}} aria-sort="ascending">ID</th>
                            <th onClick={(e) => this.orderData(e, "name")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '60%'}}>Nombre</th>
                            {this.state.role == 1 ? <th onClick={(e) => this.orderData(e, "debt")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}}>Deuda</th>: ""}
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableProvidersComponent;